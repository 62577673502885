import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ROLES } from '../config/permissions/roles';
import { ADMIN_CONFIG } from '../config/renderConfig';
import { isAwardType } from '../helpers/awardTypes';
import { translateCountryCode } from '../helpers/countryCodes';
import { matchRoles } from '../helpers/roles';
import { AWARD_TYPES } from '../models/award';
import { JUROR_GROUP_PHASES } from '../models/jurorGroup';
import { SESSION_TYPES } from '../models/session';
import Submission from '../models/submission';
import { useJuror } from './useAuth';
import { useConfig } from './useConfig';

const useSubmissionsFilter = ({
  submissions,
  setVisibleSubmissions,
  isAdminView = false,
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { juror, jurorGroup, role, session } = useJuror();
  const { ENTRIES_FILTER } = useConfig();
  const { t } = useTranslation();

  const filterParams = useMemo(
    () => ({
      data:
        searchParams
          .get('filterBy')
          ?.split(',')
          .map((p) => p.replaceAll('_cm_', ',')) || [],
      order: searchParams.get('order') || null,
      search: searchParams.get('search') || null,
    }),
    [searchParams]
  );

  useEffect(() => {
    const optionsConfig = {
      disciplineName: {
        key: 'disciplineName',
        text: t('jurorEntries.filter.options.discipline'),
        childs: submissions
          .map((submission) => submission.disciplineName)
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((disciplineName) => {
            return { key: disciplineName, text: disciplineName };
          }),
      },
      categoryName: {
        key: 'categoryName',
        text: t(
          `jurorEntries.filter.options.category.${
            session?.awardType || 'IF_SIP'
          }`
        ),
        childs: submissions
          // If already filtered by jurorGroup just show the categoryNames for that jurorGroup
          .filter(submission => {
            if (!filterParams.data.find((p) => p.startsWith('jurorGroup__'))) {
              return true;
            }
            const jurorGroups = [...new Set(submission.evaluations.map((ev) => ev.jurorGroupName))]
            const activeJurorGroup = filterParams.data.find((p) => p.startsWith('jurorGroup__'))?.split('__')[1];
            return jurorGroups.indexOf(activeJurorGroup) > -1;
          })
          .map((submission) => submission.categoryName)
          .filter((value, index, self) => self.indexOf(value) === index)
          // Category names are e.g. 1.16 Kitchen, 1.21 Public / Retail
          // Sort them by the number
          .sort((a, b) => a.split(' ')[0] - b.split(' ')[0])
          .map((categoryName) => {
            return { key: categoryName, text: categoryName };
          }),
      },
      presentationForm: {
        key: 'presentationForm',
        text: t(`jurorEntries.filter.options.presentationForm.title`),
        childs: [
          ...new Set(
            submissions
              .map((submission) =>
                submission.extraFields?.reduce(
                  (prev, field) =>
                    field.name === 'presentationForm' ? field.value : prev,
                  []
                )
              )
              .flat()
          ),
        ].map((presentationForm) => {
          return {
            key: presentationForm,
            text: t(
              `jurorEntries.filter.options.presentationForm.${presentationForm}`
            ),
          };
        }),
      },
      jurorGroup: {
        key: 'jurorGroup',
        text: t(`jurorEntries.filter.options.jurorGroup`),
        childs: [
          ...new Set(
            submissions
              .map((submission) =>
                submission.evaluations.map((ev) => ev.jurorGroupName)
              )
              .flat()
          ),
        ].map((jurorGroup) => {
          return { key: jurorGroup, text: jurorGroup };
        }),
      },
      country: {
        key: 'country',
        text: t('jurorEntries.filter.options.country'),
        childs: submissions
          .map((submission) => submission.accountCountryIsoCode)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort()
          .map((countryCode) => {
            return {
              key: translateCountryCode(countryCode),
              text: translateCountryCode(countryCode),
            };
          }),
      },
      suggestedForNomination: {
        key: 'suggestedForNomination',
        text: t('jurorEntries.filter.options.suggestedForNomination'),
      },
      nominated: {
        key: 'nominated',
        text: t(
          `jurorEntries.filter.options.nominated.${
            session?.awardType || 'IF_SIP'
          }`
        ),
      },
      chairpersonMustDecide: {
        key: 'chairPersonMustDecide',
        text: t('jurorEntries.filter.options.chairPersonMustDecide'),
      },
      discussInGroup: {
        key: 'discussInGroup',
        text: t('jurorEntries.filter.options.discussInGroup'),
      },
      toBeDecided: {
        key: 'toBeDecided',
        text: t('jurorEntries.filter.options.toBeDecided'),
      },
      bookmarked: {
        key: 'bookmarked',
        text: t('jurorEntries.filter.options.bookmarked'),
      },
      scoreAlert: {
        key: 'scoreAlert',
        text: t('jurorEntries.filter.options.scoreAlert'),
      },
      hasZeroEvaluationCategory: {
        key: 'hasZeroEvaluationCategory',
        text: t('jurorEntries.filter.options.hasZeroEvaluationCategory'),
      },
      ecoComment_unpublished: {
        key: 'ecoComment_unpublished',
        text: t('jurorEntries.filter.options.ecoComment_unpublished'),
      },
      ecoComment: {
        key: 'ecoComment',
        text: t('jurorEntries.filter.options.ecoComment'),
      },
      nominatedVeto: {
        key: 'nominatedVeto',
        text: t('jurorEntries.filter.options.nominatedVeto'),
      },
      sustainabilityScore: {
        key: 'sort_sustainabilityScore',
        text: t('jurorEntries.filter.options.sustainabilityScore'),
        sort: true,
      },
    };

    let keys = [];
    if (isAdminView) {
      keys = ADMIN_CONFIG.ENTRIES_FILTER || [];
    } else if (isAwardType(AWARD_TYPES.IF_SIP, session)) {
      keys =
        ENTRIES_FILTER.filter(
          (key) => session.prizeMoneyModeEnabled || key !== 'nominated'
        ) || [];
    } else {
      keys =
        ENTRIES_FILTER.filter((key) => {
          if (key === 'jurorGroup')
            return matchRoles(role, [ROLES.chairPerson, ROLES.staff]);
          if (key === 'scoreAlert')
            return (
              role !== ROLES.juror ||
              jurorGroup?.jurorGroupPhase !== JUROR_GROUP_PHASES.editPhase
            );
          if (key === 'presentationForm')
            return session?.sessionType === SESSION_TYPES.FINAL_JURY;
          if (key === 'hasZeroEvaluationCategory') return role === ROLES.staff;
          if (key === 'nominated')
            return session?.sessionType === SESSION_TYPES.FINAL_JURY;
          if (key === 'suggestedForNomination')
            return (
              !isAwardType(AWARD_TYPES.IF_DA, session) ||
              session?.sessionType !== SESSION_TYPES.PRESELECTION
            );
          if (key === 'ecoComment_unpublished')
            return (
              isAwardType(AWARD_TYPES.IF_DA, session) &&
              session?.sessionType === SESSION_TYPES.FINAL_JURY &&
              role === ROLES.staff
            );
          if (['ecoComment', 'nominatedVeto'].includes(key))
            return (
              isAwardType(AWARD_TYPES.IF_DA, session) &&
              session?.sessionType === SESSION_TYPES.FINAL_JURY
            );
          if (key === 'sustainabilityScore')
            return (
              isAwardType(AWARD_TYPES.IF_DA, session) &&
              matchRoles(role, [ROLES.staff]) &&
              session?.sessionType === SESSION_TYPES.FINAL_JURY
            );
          return true;
        }) || [];
    }

    setFilterOptions(keys.map((key) => optionsConfig[key]));
  }, [submissions, session, isAdminView, jurorGroup, filterParams]);

  useEffect(() => {
    let filtered = submissions;

    if (filterParams?.search) {
      filtered = Submission.search(filtered, filterParams?.search);
    }
    if (filterParams?.data) {
      filtered = Submission.filter(filtered, filterParams?.data, juror, role);
    }
    if (filterParams?.order) {
      const sortKey = filterParams?.order.split('____')[0];
      const sortDirection = filterParams?.order.split('____')[1];
      filtered = Submission.sort(filtered, {
        key: sortKey,
        direction: sortDirection,
      });
    }

    setVisibleSubmissions(filtered);
  }, [filterParams, submissions]);

  const handleSortChange = (value) => {
    let newFilterParams = [...filterParams.data];

    if (value.startsWith('sort_')) {
      return onSort(value);
    }

    if (value.includes('__')) {
      const paramKey = value.split('__')[0];
      const paramIndex = newFilterParams.findIndex(
        (param) => param.includes('__') && param.split('__')[0] === paramKey
      );

      if (paramIndex > -1) {
        if (newFilterParams.find((el) => el === value)) {
          newFilterParams = newFilterParams.filter((el) => el !== value);
        } else {
          newFilterParams[paramIndex] = value;
        }
      } else {
        newFilterParams.push(value);
      }
    } else {
      if (newFilterParams.find((el) => el === value)) {
        newFilterParams = newFilterParams.filter((el) => el !== value);
      } else {
        newFilterParams.push(value);
      }
    }

    if (newFilterParams.length) {
      searchParams.set(
        'filterBy',
        newFilterParams.map((p) => p.replaceAll(',', '_cm_')).join(',')
      );
    } else {
      searchParams.delete('filterBy');
    }
    setSearchParams(searchParams);
  };

  const handleFilterDeselect = (value) => {
    if (filterParams.data.length > 1) {
      searchParams.set(
        'filterBy',
        filterParams.data.filter((p) => p !== value).join(',')
      );
      return setSearchParams(searchParams);
    }
    handleFilterReset('filterBy');
  };

  const onFulltextSearch = (searchTerm) => {
    if (searchTerm == '') {
      searchParams.delete('search');
    } else {
      searchParams.set('search', searchTerm);
    }
    setSearchParams(searchParams);
  };

  const handleFilterReset = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const onSort = (key = 'sort_averageScore') => {
    let newOrder = '';

    const currentOrder = searchParams.get('order');
    const oldKey = currentOrder?.split('____')[0];
    const oldDirection = currentOrder?.split('____')[1];

    if (oldKey !== key || (oldKey === key && oldDirection === 'desc')) {
      newOrder = `${key}____asc`;
    }

    if (oldKey === key && oldDirection === 'asc') {
      newOrder = `${key}____desc`;
    }

    searchParams.set('order', newOrder);
    setSearchParams(searchParams);
  };

  return {
    filterParams,
    filterOptions,
    handleSortChange,
    handleFilterDeselect,
    handleFilterReset,
    onFulltextSearch,
    onSort,
  };
};

export default useSubmissionsFilter;
