import { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCalendar,
  faCameraSlash,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowRight as faArrowRightBold } from '@fortawesome/pro-regular-svg-icons';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import Button, { ButtonTypes } from '../../button';
import { getSVGURI } from '../../../helpers/fontawesome';
import Carousel from './carousel';
import PermissionHandler from '../../../modules/permissionHandler';
import { ROLES, SCOPES } from '../../../config/permissions/roles';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import Task from '../../../models/task';
import { useConfig } from '../../../hooks/useConfig';
import AwardBadge from '../../badge';
import { isAwardType } from '../../../helpers/awardTypes';
import { AWARD_TYPES } from '../../../models/award';

const DashboardTask = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { juror, session, role, jurorGroup } = useJuror();

  const [isLoading, setIsLoading] = useState(true);
  const [task, setTask] = useState(null);
  const {
    AWARD_YEAR,
    DASHBOARD_CONFIG: { task: taskWidgetConfig },
  } = useConfig();

  useEffect(() => {
    if (juror) {
      Promise.all(
        juror.jurorGroups.map(({ jurorGroupId }) => Task.fetch(jurorGroupId))
      ).then((tasksData) => {
        const taskData = Task.from(
          tasksData.reduce(
            (prev, curr) => ({
              ...curr,
              totalEvaluationCount:
                prev.totalEvaluationCount + curr.totalEvaluationCount,
              notDecidedEvaluationCount:
                prev.notDecidedEvaluationCount + curr.notDecidedEvaluationCount,
            }),
            new Task()
          )
        );
        setTask(taskData);
        setIsLoading(false);
      });
    }
  }, [juror, setTask, setIsLoading]);

  const backgroundImage = useMemo(() => {
    const imageUrl = task?.submissionsPreview.find(
      (submission) => submission.submissionPrimaryMediaUrl
    )?.submissionPrimaryMediaUrl;

    return {
      isEmpty: !imageUrl,
      style: {
        backgroundImage: `url(${
          imageUrl || getSVGURI(faCameraSlash, 'black')
        })`,
      },
    };
  }, [task]);

  const progressText = t('jurorDashboard.task.progress', {
    numNotDecided: task?.notDecidedEvaluationCount,
    total: task?.totalEvaluationCount,
  });

  const handleTaskClick = (e) => {
    const params = new URLSearchParams();

    if (isAwardType(AWARD_TYPES.IF_DTA, session)) {
      params.set('order', 'sort_averageScore____asc');
    }

    if (isAwardType(AWARD_TYPES.IF_DA, session) && role === ROLES.chairPerson) {
      params.set('filterBy', 'chairPersonMustDecide,toBeDecided');
    } else if (role === ROLES.staff) {
      const jurorGroupName = jurorGroup?.jurorGroupName;
      params.set('filterBy', `jurorGroup__${jurorGroupName}`);
    }

    if (params.size) {
      return navigate(`../entries?${params.toString()}`);
    }

    navigate('../entries');
  };

  return (
    <div className={combine(styles.taskContainer, 'placeholder-glow')}>
      {isLoading ? (
        <span className={combine('placeholder', styles.image)}></span>
      ) : (
        <>
          <PermissionHandler
            scopes={[SCOPES.juror, SCOPES.groupLeader, SCOPES.staff]}
          >
            <div
              style={backgroundImage.style}
              className={combine(
                styles.image,
                backgroundImage.isEmpty && styles.empty
              )}
            ></div>
          </PermissionHandler>
          <PermissionHandler scopes={[SCOPES.chairPerson]}>
            <div className={styles.image}>
              <Carousel preview={task?.submissionsPreview} />
            </div>
          </PermissionHandler>
        </>
      )}
      {!isLoading && (
        <AwardBadge
          awardType={session.awardType}
          year={AWARD_YEAR}
          className={styles.logo}
        />
      )}
      <div className={styles.textContainer}>
        <h3
          className={combine(
            styles.awardTitle,
            styles.separate,
            'placeholder-glow'
          )}
        >
          {isLoading ? (
            <span
              className={combine(styles.placeholder, 'placeholder col-8')}
            ></span>
          ) : (
            `${task?.awardName} ${task?.awardYear}`
          )}
        </h3>
        <div className={combine(styles.metaDataContainer, 'placeholder-glow')}>
          {isLoading && <span className={'placeholder col-5'}></span>}
          <span className={combine(styles.progressText, styles.separate)}>
            {!isLoading && progressText}
          </span>
        </div>
        <div className={combine(styles.bottomRowContainer, 'placeholder-glow')}>
          {isLoading && <span className="placeholder col-5"></span>}
          <div className={styles.smallTextContainer}>
            <span className={combine(styles.smallText, styles.separate)}>
              {!isLoading && t(`sessionTypes.${session?.sessionType}`)}
            </span>
            <span className={combine(styles.smallText, styles.separate)}>
              {!isLoading && taskWidgetConfig?.showEndDate && (
                <>
                  <FontAwesomeIcon icon={faCalendar} />
                  <span>
                    {t('jurorDashboard.task.endDate', {
                      date: moment(session?.endDate).utc().format('DD.MM.YYYY'),
                    })}
                  </span>
                </>
              )}
            </span>
          </div>
          {!isLoading && (
            <Button
              card
              className={styles.button}
              icon={faArrowRight}
              iconBold={faArrowRightBold}
              type={ButtonTypes.TERTIARY}
              text={
                role === ROLES.staff
                  ? t('jurorDashboard.task.view')
                  : t('jurorDashboard.task.evaluate')
              }
              onClick={handleTaskClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTask;
