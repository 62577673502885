import { BrowserQRCodeReader } from '@zxing/browser';
import { useEffect, useState } from 'react';

export const SCAN_STATES = {
  NOT_SCANNING: 'not_scanning',
  SCANNING: 'scanning',
  STOPPING: 'stopping',
};

export default function useQrCodeScanner(htmlVideoElementId) {
  const [data, setData] = useState('');
  const [controls, setControls] = useState([]);
  const [scanState, setScanState] = useState(SCAN_STATES.NOT_SCANNING);

  // Effect for starting the qr code scanner.
  useEffect(() => {
    async function startScan() {
      const codeReader = new BrowserQRCodeReader();
      const currentControls = await codeReader.decodeFromConstraints(
        { video: { facingMode: 'environment' } },
        htmlVideoElementId,
        (result) => {
          if (result?.text) {
            setData(result.text);
            setScanState(SCAN_STATES.STOPPING);
          }
        }
      );

      setScanState(SCAN_STATES.SCANNING);
      setControls((prev) => [...prev, currentControls]);
    }

    startScan();
  }, []);

  // Effect for stopping the qr code scanner.
  useEffect(() => {
    // Stop the scan, if qr code is found.
    if (scanState === SCAN_STATES.STOPPING && controls.length) {
      controls.forEach((control) => {
        control.stop();
      });

      setControls([]);
      setScanState(SCAN_STATES.NOT_SCANNING);
    }

    // Stop the scan correctly when the component is unmounted.
    return () => {
      if (controls.length) {
        controls.forEach((control) => {
          control.stop();
        });
      }
    };
  }, [controls, scanState]);

  return { scanState, data };
}
