export const ECO_QUESTIONS = [
  {
    fieldname: 'architectureEcoQuestions1',
    question:
      'Which design decisions were made to prioritize lower energy consumption and reduced resource use during daily operations?',
    answers: [
      'Materials with low environmental impact (e.g., recycled, locally sourced, etc.)',
      'Passive design strategies (e.g., natural ventilation, daylighting, etc.)',
      'Other',
      'Not sure / Not applicable',
      'Ongoing social and/or environmental measurement and monitoring',
      'Planned obsolescence incl. disassembly and material reuse and recycling',
      'Flexible and adaptable spaces that can be easily retrofitted or modified for future changes',
      'Renewable energy systems (e.g., solar panels, wind turbines, geothermal, etc.)',
      'Water-saving fixtures and systems (e.g., low-flow faucets, dual-flush toilets, etc.)',
      'Energy-efficient lighting systems (e.g., LED fixtures, automated sensors, etc.)',
    ],
  },
  {
    fieldname: 'architectureEcoQuestions2',
    question:
      'In what ways does your design support and promote human health and well-being from construction through end-of-life?',
    answers: [
      'Flexible spaces for multi-use',
      'Not sure / Not applicable',
      'Use of materials from fair labor sources',
      'Pay construction workers a livable wage',
      'Prioritize construction worker safety (e.g., protective equipment, training, rest areas, etc.)',
      'Execute clear waste management strategies (e.g., recycling, salvage, hazardous waste, etc.)',
      'Other',
      'Use of highly durable materials with low environmental impact',
      'Personal wellness options built into the design',
      'Healthy air circulation, filtration, and light',
      'Use of low-embodied carbon materials',
      'Use of non-toxic materials (low VOC)',
    ],
  },
  {
    fieldname: 'architectureEcoQuestions3',
    question: 'How do you ensure equal access for all users?',
    answers: [
      'Install proper lighting to enhance visibility and safety',
      'Implement clear wayfinding',
      'Implement universal design principles',
      'Other',
      'Not sure / Not applicable',
      'Engage the community and account for broader inclusion in all public or shared spaces',
      'Provide gender- and culturally-sensitive private areas',
      'Consider diverse sensory needs, such as noise levels and tactile experience',
      'Incorporate adaptive equipment and features',
      'Use contrasting colors and textures to aid individuals with visual impairments',
      'Create clear pathways and circulation routes for wheelchair accessibility',
    ],
  },
  {
    fieldname: 'communicationEcoQuestion1',
    question:
      'What design decisions were taken to prioritize environmental sustainability?',
    answers: [
      'Minimize data transfer and server load by optimizing file sizes and formats',
      'Use greenest materials and techniques available for any physical asset production',
      'Reduce/Remove physical outputs whenever possible for a lower energy and material footprint',
      'Other',
      'Not sure / Not applicable',
      'Host digital assets on servers powered by renewable energy sources (e.g., solar, wind, etc.)',
      'Implement energy-efficient coding practices and website optimization techniques',
      'Select energy- and waste-optimized fonts',
    ],
  },
  {
    fieldname: 'communicationEcoQuestion2',
    question:
      'How did you design this entry to be inclusive of diverse audiences (e.g., gender, ethnicity, disability, age, etc.)?',
    answers: [
      'Not sure / Not applicable',
      'Include stakeholders of diverse backgrounds in the design process (e.g., focus groups, creative talent, etc.)',
      'Represent people of diverse backgrounds in visuals, including casting',
      'Reduce text length and complexity',
      'Use inclusive language',
      'Present in different languages',
      'Other',
    ],
  },
  {
    fieldname: 'communicationEcoQuestion3',
    question: 'How does your design ensure equal access for all users?',
    answers: [
      'Offers ethical pricing, lower-cost and/or payment-free options',
      'Suggests alternate content delivery methods and/or user interactions (e.g., text-to-speech, braille, etc.)',
      'Delivers information in as easy-to-understand language as possible',
      'Presents relevant language choices/options',
      'Other',
      'Not sure / Not applicable',
      'Provides enhanced readability/visibility options (e.g., adjustable fonts, brightness, color, etc.)',
    ],
  },
  {
    fieldname: 'communicationEcoQuestion4',
    question:
      'What design decisions were taken to prioritize environmental sustainability?',
    answers: [
      'Implement energy-efficient coding practices and website optimization techniques',
      'Minimize data transfer and server load by optimizing file sizes and formats',
      'Select energy- and waste-optimized inputs (e.g., music, fonts, images, lighting, etc.)',
      'Remote versus travel for in-person collaboration',
      'Other',
      'Not sure / Not applicable',
      'Host digital assets on servers powered by renewable energy sources (e.g., solar, wind, etc.)',
    ],
  },
  {
    fieldname: 'communicationEcoQuestion5',
    question:
      'What material decisions were made in design and production to prioritize environmental sustainability?',
    answers: [
      'Other',
      'Tree-free paper alternatives',
      'Water-based coatings and finishes',
      'Soy-based or vegetable-based inks',
      'Recycled paper content, including post-consumer waste',
      'FSC-certified paper from responsibly-managed forests',
      'Not sure / Not applicable',
      'Waste-optimized fonts',
      'Upcycled or reclaimed materials',
    ],
  },
  {
    fieldname: 'interriorArchitectureEcoQuestions1',
    question:
      'What design decisions were made to prioritize lower energy consumption and reduced resource use during daily operations?',
    answers: [
      'Passive design strategies (e.g., natural ventilation, daylighting, etc.)',
      'Materials with low environmental impact (e.g., recycled, locally sourced, etc.)',
      'Energy-efficient lighting systems (e.g., LED fixtures, automated sensors, etc.)',
      'Water-saving fixtures and systems (e.g., low-flow faucets, dual-flush toilets, etc.)',
      'Renewable energy systems (e.g., solar panels, wind turbines, geothermal, etc.)',
      'Flexible and adaptable spaces that can be easily retrofitted or modified for future changes',
      'Space-saving and multifunctional furniture',
      'Planned obsolescence incl. disassembly and material reuse and recycling',
      'Ongoing social and/or environmental measurement and monitoring',
      'Not sure / Not applicable',
      'Other',
    ],
  },
  {
    fieldname: 'interriorArchitectureEcoQuestions2',
    question:
      'In what ways does your design support and promote human health and well-being from construction through end-of-life?',
    answers: [
      'Pay construction workers a livable wage',
      'Not sure / Not applicable',
      'Use of materials with high recycled content',
      'Use of materials from fair labor sources',
      'Use of compostable materials',
      'Use of low-embodied carbon materials',
      'Use of non-toxic materials (low VOC)',
      'Other',
      'Select furniture and fixtures that are easy to use and adjust',
      'Flexible spaces for multi-use',
      'Personal wellness options built into the design',
      'Healthy air circulation, filtration, and light',
      'Execute clear waste management strategies (e.g., recycling, salvage, etc.)',
      'Prioritize construction worker safety (e.g., protective equipment, training, rest areas, etc.)',
    ],
  },
  {
    fieldname: 'interriorArchitectureEcoQuestions3',
    question: 'How do you ensure equal access for all users?',
    answers: [
      'Not sure / Not applicable',
      'Select furniture and fixtures that are easy to use and adjustable',
      'Incorporate adaptive equipment and features, such as adjustable-height countertops',
      'Consider diverse sensory needs, such as noise levels and tactile experience',
      'Provide gender- and culturally-sensitive private areas',
      'Engage broad stakeholders and account for inclusion in any public or shared spaces',
      'Other',
      'Implement universal design principles',
      'Implement clear wayfinding',
      'Install proper lighting to enhance visibility and safety',
      'Create clear pathways and circulation routes for wheelchair accessibility',
      'Use contrasting colors and textures to aid individuals with visual impairments',
    ],
  },
  {
    fieldname: 'packagingEcoQuestion1',
    question: 'What end-of-life scenario(s) best describes this design?',
    answers: [
      'One-time use – compostable',
      'One-time use – landfill/incineration',
      'Reusable',
      'Other',
      'Not sure / Not applicable',
      'Recyclable – chemical',
      'Recyclable – mechanical',
    ],
  },
  {
    fieldname: 'packagingEcoQuestion2',
    question:
      'What material and/or design decisions were made to reduce the packaging’s environmental impact?',
    answers: [
      'Non-virgin, non-renewable materials',
      'Non-virgin, renewable materials',
      'Virgin, non-renewable materials',
      'Virgin, renewable materials',
      'Non-toxic inks',
      'Mono-materials that can be clearly separated at end of life',
      'Other',
      'Not sure / Not applicable',
      'Re-use elements from shipping to distribution/point of sale',
      'Compacted footprint and/or other shipping efficiencies',
      'Reduced overall packaging weight and/or packaging-to-product ratio',
      'Digital watermarking and/or other traceability technology',
    ],
  },
  {
    fieldname: 'packagingEcoQuestion3',
    question:
      'How do you encourage appropriate end-of-life disposal (e.g., recyclable, compostable, landfill)?',
    answers: [
      'Not sure / Not applicable',
      'Clear labelling',
      'Easily separable materials',
      'Second life/Extension innovations',
      'Return service and/or rewards program',
      'Service design partnerships for reuse/recycle/upcycle',
      'Other',
    ],
  },
  {
    fieldname: 'productEcoQuestion1',
    question:
      'What sustainable design strategies are incorporated into the design of this product?',
    answers: [
      'Refurbish - provide opportunities for reusing products and secondary markets ',
      'Remanufacture - create products that leverage reused components ',
      'Rethink - avoid using unnecessary or unsustainable elements',
      'Refuse - this item prevents the creation of unnecessary product(s)',
      'Not sure / Not applicable',
      'Reduce - less material and/or energy used where possible',
      'Reuse - repurpose components, materials, products, or processes',
      'Recycle - utilize post-industrial or post-consumer materials ',
      'Maintenance - enable the longevity of the product',
      'Repair - leverage standard components and provide replacement parts',
    ],
  },
  {
    fieldname: 'productEcoQuestion1a',
    question:
      'How is sustainability prioritized in the design of this vehicle?',
    answers: [
      'Other',
      'Not sure / Not applicable',
      'End-of-use service offering (e.g., take-back, repair, donation, etc.)',
      'Emphasis on repairability and long service life',
      'Innovative use of upcycled/recycled material in its construction',
      'Production processes powered by renewable energy',
      'Powered by renewable energy',
      'More efficient than a typical vehicle in its class',
    ],
  },
  {
    fieldname: 'productEcoQuestion2',
    question:
      'How did you design this product to promote inclusivity, accessibility, and affordability for all users?',
    answers: [
      'Other',
      'Incorporate universal design principles',
      'Integrate features that cater to individuals with disabilities or special requirements',
      'Ensure ethical pricing across audience segments',
      'Collaborate with special user groups and/or experts for feedback and insights',
      'Not sure / Not applicable',
    ],
  },
  {
    fieldname: 'productEcoQuestion2a',
    question:
      'How did you design this vehicle to promote inclusivity, accessibility, and affordability for all users?',
    answers: [
      'Other',
      'Incorporate universal design principles',
      'Integrate features that cater to individuals with disabilities or special requirements',
      'Ensure ethical pricing across audience segments',
      'Collaborate with special user groups and/or experts for feedback and insights',
      'Not sure / Not applicable',
    ],
  },
  {
    fieldname: 'productEcoQuestion3',
    question:
      'What design decisions did you take to encourage more sustainable user behavior?',
    answers: [
      'Create interactive user interfaces or apps that provide sustainably-minded tips and guidance',
      "Provide educational materials such as user manuals or online resources that explain the product's lifecycle and carbon footprint",
      'Incorporate informative labeling that highlights the product’s environmental impact',
      'Other',
      'Not sure / Not applicable',
      'Offer end-of-use services (e.g. take-back, repair, donation, etc.)',
      'Integrate feedback mechanisms into the product that inform users of their resource consumption',
      'Set all default operating standards to the most sustainable performance',
    ],
  },
  {
    fieldname: 'productEcoQuestion3a',
    question:
      'What design decisions did you take to encourage more sustainable user behavior?',
    answers: [
      'Other',
      'Incorporate visual design cues that communicate the vehicle’s commitment to sustainability',
      "Provide educational materials such as user manuals or online resources that explain the product's lifecycle and environmental impact",
      'Create interactive user interfaces or apps that highlight the vehicle’s environmental impact and opportunities for improvement',
      'Integrate feedback mechanisms into the vehicle that inform drivers to conserve energy/power during operation',
      'Set default operating standards to the most environmentally sensitive performance',
      'Provide instructions for eco-friendly use and disposal (including parts)',
      'Not sure / Not applicable',
    ],
  },
  {
    fieldname: 'serviceDesignEcoQuestion1',
    question:
      'What design decisions were taken to prioritize environmental sustainability?',
    answers: [
      'Reduce energy usage and/or utilize renewable sources (e.g., solar, wind, etc.)',
      'Prioritize the use of energy-efficient technologies (e.g., LEDs with sensors, peak load reduction, etc.)',
      'Support biodiversity initiatives in relevant local communities (e.g., food waste composting, pollinator protections, etc.)',
      'Evaluate carbon emissions generated across the user journey',
      'Commit to ongoing social and/or environmental measurement and monitoring',
      'Not sure / Not applicable',
      'Other',
      'Materials and techniques minimize pollution and enable circularity whenever possible',
      'Incorporate digital solutions to reduce, remove, and track materials used whenever possible',
      'Use 100% recycled materials whenever possible',
      'Prioritize sourcing relevant material streams from regenerative suppliers',
      'Prioritize sourcing reused or upcycled components',
      'Provide reusable or refillable packaging',
    ],
  },
  {
    fieldname: 'serviceDesignEcoQuestion2',
    question:
      'How have you designed your service’s various touchpoints to promote inclusivity, accessibility, and affordability for all users?',
    answers: [
      'Democratize access, including affordable pricing, relevant discounts, and rewards',
      'Provide options for hearing-impaired users (e.g., volume controls, text-to-speech, etc.)',
      'Provide options for mobility-challenged users',
      'Provide options for visually-impaired users  (e.g., braille, adjustable fonts, brightness, color, etc.)',
      'Present Information as clearly as possible',
      'Use inclusive language',
      'Present different language choices/options',
      'Provide educational resources for user learning and development',
      'Provide personalized service options to meet individual preferences, including cultural needs',
      'Represent diversity in marketing materials and team hiring',
      'Other',
      'Include stakeholders of diverse backgrounds in the design process (e.g., focus groups, creative talent, etc.)',
      'Train staff and provide superior customer support (e.g., 24/7, human-powered, etc.)',
      'Not sure / Not applicable',
      'Account for local community inclusion and partnership',
    ],
  },
  {
    fieldname: 'serviceDesignEcoQuestion3',
    question:
      'In what ways does your service support and promote human and societal well-being?',
    answers: [
      'Partner with NGOs or similar experts to support social and/or environmental causes',
      'Support disadvantaged and/or marginalized communities (e.g., homeless, refugees, disabled, etc.',
      'Support community initiatives (e.g., education, healthcare, volunteering, etc.)',
      'Procurement practices prioritize small businesses, local economies, and environmental impact',
      'Provide training and development opportunities to team members',
      'Pay a living wage and provide a safe working environment',
      'Prioritize local hiring',
      'Promote employee health and work-life balance',
      'Other',
      'Not sure / Not applicable',
      'Create virtuous cycle / positive feedback loops within our user and supplier communities',
    ],
  },
  {
    fieldname: 'uiDesignEcoQuestion1',
    question:
      'What design decisions were taken to prioritize environmental sustainability?',
    answers: [
      'Other',
      'Not sure / Not applicable',
      'Ongoing measurement and monitoring for optimization',
      'Set default operating standards to the most environmentally sensitive performance',
      'Guide users toward lower footprint choices',
      'Indicate the user’s overall footprint and contribution',
      'Power digital services with renewable energy (e.g., solar, wind, etc.)',
      'Reduce load times to reduce energy consumption',
      'Select energy-optimized fonts',
      'Create user flows that reduce energy consumption',
    ],
  },
  {
    fieldname: 'uxDesignEcoQuestion1',
    question:
      'What design decisions were taken to prioritize environmental sustainability?',
    answers: [
      'Reduce/Remove physical outputs whenever possible for a lower energy and material footprint',
      'Use greenest materials and techniques available for any physical asset production',
      'Create user flows that reduce energy consumption',
      'Power digital services with renewable energy (e.g., solar, wind, etc.)',
      'Reduce load times to reduce energy consumption',
      'Indicate material choices and educate on the reason why',
      'Ongoing measurement and monitoring for optimization',
      'Set default operating standards to the most environmentally sensitive performance',
      'Guide users toward lower energy/materials footprint choice',
      'Indicate the user’s overall footprint and contribution',
      'Other',
      'Not sure / Not applicable',
      'Raise awareness for environmental issues',
    ],
  },
];

export function getEcoQuestionFieldNames() {
  return ECO_QUESTIONS.map((question) => question.fieldname);
}
