import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight as faArrowRightBold } from '@fortawesome/pro-regular-svg-icons';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Button, { ButtonTypes } from '../../button';
import { combine } from '../../../helpers/styles';
import InformationMedia from '../../../models/informationMedia';
import { useJuror } from '../../../hooks/useAuth';
import { SESSION_TYPES } from '../../../models/session';

const InformationList = () => {
  const [hoverItem, setHoverItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [informationMaterial, setInformationMaterial] = useState([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { jurorGroup, session } = useJuror();

  useEffect(() => {
    if (jurorGroup) {
      setIsLoading(true);
      InformationMedia.jurorFetchAllForGroup(jurorGroup?.jurorGroupId).then(
        (informationMaterial) => {
          setInformationMaterial(informationMaterial);
          setIsLoading(false);
        }
      );
    }
  }, [setInformationMaterial, setIsLoading, jurorGroup]);

  const visibleInformationMaterial =
    informationMaterial.length > 3
      ? informationMaterial.slice(0, 3)
      : informationMaterial;

  const handleAllButtonClick = (e) => {
    navigate('../help');
  };

  return (
    <div className={styles.informationList}>
      <div className={styles.listContainer}>
        <ul className={styles.list}>
          {isLoading
            ? new Array(3).fill(null).map((_, index) => (
                <div
                  key={index}
                  className={combine(styles.item, 'placeholder-glow')}
                >
                  <span
                    className={combine(styles.placeholder, 'placeholder col-8')}
                  ></span>
                </div>
              ))
            : visibleInformationMaterial.map((item) => (
                <Link
                  to={`../help#${item.id}`}
                  id={item.id}
                  className={styles.item}
                  key={item.id}
                  onMouseEnter={() => {
                    setHoverItem(item.id);
                  }}
                  onMouseLeave={() => {
                    setHoverItem(null);
                  }}
                >
                  <span className={styles.text}>{item.title}</span>
                  <FontAwesomeIcon
                    icon={
                      hoverItem === item.id ? faArrowRightBold : faArrowRight
                    }
                    className={styles.icon}
                  />
                </Link>
              ))}
        </ul>
      </div>
      <div
        className={combine(
          styles.buttonContainer,
          // Small hack to match the widget height to the statistic widget for final jury
          session?.sessionType === SESSION_TYPES.FINAL_JURY && styles.finalJury,
          'placeholder-glow'
        )}
      >
        {isLoading ? (
          <span
            className={combine(styles.buttonPlaceholder, 'placeholder col-4')}
          ></span>
        ) : (
          <Button
            text={t('jurorDashboard.informationList.allButton')}
            type={ButtonTypes.SECONDARY}
            onClick={handleAllButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default InformationList;
