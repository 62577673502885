import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ChatWidget from '../../../components/dashboard/chatWidget';
import DashboardTask from '../../../components/dashboard/dashboardTask';
import GroupMembers from '../../../components/dashboard/groupMembers';
import InformationList from '../../../components/dashboard/informationList';
import MeetingsWidget from '../../../components/dashboard/meetingsWidget';
import StatisticWidget from '../../../components/dashboard/statisticWidget';
import { ROLES } from '../../../config/permissions/roles';
import { useJuror } from '../../../hooks/useAuth';
import { useConfig } from '../../../hooks/useConfig';
import { SESSION_TYPES } from '../../../models/session';
import Hero from '../../../modules/hero';
import styles from './styles.module.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const { juror, session, role } = useJuror();
  const { DASHBOARD_CONFIG } = useConfig();
  const hasBigStatistic =
    role === ROLES.chairPerson &&
    DASHBOARD_CONFIG?.statistic &&
    DASHBOARD_CONFIG?.BIG_STATISTIC[session?.sessionType];
  const jurorGroups = juror?.jurorGroups;

  return (
    <div className={styles.dashboard}>
      <Hero
        multiLineTitle
        title={{
          firstRow: `${t('jurorDashboard.hero.title.firstRow')} ${
            juror?.firstname
          }!`,
          secondRow: t('jurorDashboard.hero.title.secondRow'),
        }}
        bigTitle
      />
      <Container className={styles.content}>
        <Row className={styles.row}>
          <Col xl={8} xs={12} className={styles.widget}>
            {DASHBOARD_CONFIG?.task?.visible && (
              <>
                <span className={styles.title}>
                  {t('jurorDashboard.task.title')}
                </span>
                <DashboardTask />
              </>
            )}
          </Col>
          <Col xl={4} xs={12} className={styles.widget}>
            <>
              {DASHBOARD_CONFIG?.meetings ? (
                <>
                  <span className={styles.title}>
                    {t('jurorDashboard.meetings.title')}
                  </span>
                  <MeetingsWidget />
                </>
              ) : DASHBOARD_CONFIG?.information ? (
                <>
                  <span className={styles.title}>
                    {t('jurorDashboard.informationList.title')}
                  </span>
                  <InformationList />
                </>
              ) : (
                <></>
              )}
            </>
          </Col>
        </Row>
        {hasBigStatistic ? (
          jurorGroups.map((jurorGroup, index) => (
            <Row className={styles.row}>
              <Col xl={8} xs={12} className={styles.widget}>
                {!index && (
                  <span className={styles.title}>
                    {t('jurorDashboard.statistic.title')}
                  </span>
                )}
                <StatisticWidget customJurorGroup={jurorGroup} />
              </Col>
              {index === 0 && (
                <Col xl={4} xs={12} className={styles.widget}>
                  {DASHBOARD_CONFIG?.information && (
                    <>
                      <span className={styles.title}>
                        {t('jurorDashboard.informationList.title')}
                      </span>
                      <InformationList />
                    </>
                  )}
                </Col>
              )}
            </Row>
          ))
        ) : (
          <Row className={styles.row}>
            <Col xl={8} xs={12} className={styles.widget}>
              {DASHBOARD_CONFIG?.statistic && (
                <>
                  <span className={styles.title}>
                    {t('jurorDashboard.statistic.title')}
                  </span>
                  <StatisticWidget />
                </>
              )}
            </Col>
            <Col xl={4} xs={12} className={styles.widget}>
              {DASHBOARD_CONFIG?.information && DASHBOARD_CONFIG?.meetings && (
                <>
                  <span className={styles.title}>
                    {t('jurorDashboard.informationList.title')}
                  </span>
                  <InformationList />
                </>
              )}
            </Col>
          </Row>
        )}
        <Row className={styles.row}>
          {(role !== ROLES.chairPerson ||
            session?.sessionType !== SESSION_TYPES.FINAL_JURY) && (
            <>
              <Col xl={4} xs={12} className={styles.widget}>
                {DASHBOARD_CONFIG?.groupMembers && (
                  <>
                    <span className={styles.title}>
                      {t('jurorDashboard.groupMembers.title')}
                    </span>
                    <GroupMembers />
                  </>
                )}
              </Col>
              <Col
                xl={hasBigStatistic ? 4 : 8}
                xs={12}
                className={styles.widget}
              >
                {DASHBOARD_CONFIG?.chat && (
                  <>
                    <span className={styles.title}>
                      {t('jurorDashboard.chat.title')}
                    </span>
                    <ChatWidget />
                  </>
                )}
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
