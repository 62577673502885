class JurorGroupEvaluations {
  constructor(
    jurorGroupId,
    jurorGroupName,
    jurorGroupPhase,
    lowestSubmissionIdInGroup,
    primaryMediaUrlOfLowestSubmissionIdInGroup,
    primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup,
    numJurorsInGroup,
    numEvaluatingJurorsInGroup,
    numSubmissionsInGroup,
    numScoreAlerts,
    numSubmissionsWithSuggestedForNomination,
    numNominationsInGroup,
    numEvaluationsWithChairPersonMustDecide,
    numDecidedEvaluations,
    totalPointsFromAllDecidedEvaluations,
    numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneHalve,
    numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneThird,
    numFullyDecidedSubmissions
  ) {
    this.jurorGroupId = jurorGroupId;
    this.jurorGroupName = jurorGroupName;
    this.jurorGroupPhase = jurorGroupPhase;
    this.lowestSubmissionIdInGroup = lowestSubmissionIdInGroup;
    this.primaryMediaUrlOfLowestSubmissionIdInGroup =
      primaryMediaUrlOfLowestSubmissionIdInGroup;
    this.primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup =
      primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup;
    this.numJurorsInGroup = numJurorsInGroup;
    this.numEvaluatingJurorsInGroup = numEvaluatingJurorsInGroup;
    this.numSubmissionsInGroup = numSubmissionsInGroup;
    this.numScoreAlerts = numScoreAlerts;
    this.numSubmissionsWithSuggestedForNomination =
      numSubmissionsWithSuggestedForNomination;
    this.numNominationsInGroup = numNominationsInGroup;
    this.numEvaluationsWithChairPersonMustDecide =
      numEvaluationsWithChairPersonMustDecide;
    this.numDecidedEvaluations = numDecidedEvaluations;
    this.totalPointsFromAllDecidedEvaluations =
      totalPointsFromAllDecidedEvaluations;
    this.numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneHalve =
      numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneHalve;
    this.numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneThird =
      numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneThird;
    this.numFullyDecidedSubmissions = numFullyDecidedSubmissions;
  }

  static from = (jurorGroup) => {
    return new JurorGroupEvaluations(
      jurorGroup.jurorGroupId,
      jurorGroup.jurorGroupName,
      jurorGroup.jurorGroupPhase,
      jurorGroup.lowestSubmissionIdInGroup,
      jurorGroup.primaryMediaUrlOfLowestSubmissionIdInGroup,
      jurorGroup.primaryMediaThumbnailUrlOfLowestSubmissionIdInGroup,
      jurorGroup.numJurorsInGroup,
      jurorGroup.numEvaluatingJurorsInGroup,
      jurorGroup.numSubmissionsInGroup,
      jurorGroup.numScoreAlerts,
      jurorGroup.numSubmissionsWithSuggestedForNomination,
      jurorGroup.numNominationsInGroup,
      jurorGroup.numEvaluationsWithChairPersonMustDecide,
      jurorGroup.numDecidedEvaluations,
      jurorGroup.totalPointsFromAllDecidedEvaluations,
      jurorGroup.numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneHalve,
      jurorGroup.numFullyDecidedSubmissionsWithPointsAboveSessionMedianOneThird,
      jurorGroup.numFullyDecidedSubmissions
    );
  };

  static of = (jurorGroups) => {
    return jurorGroups?.map((jurorGroup) =>
      JurorGroupEvaluations.from(jurorGroup)
    );
  };
}

class JurorGroupEvaluationsOverview {
  constructor(
    maxPointsPerCriterion,
    numCriteria,
    numJurorsInSession,
    numEvaluatingJurorsInSession,
    jurorGroups
  ) {
    this.maxPointsPerCriterion = maxPointsPerCriterion;
    this.numCriteria = numCriteria;
    this.numJurorsInSession = numJurorsInSession;
    this.numEvaluatingJurorsInSession = numEvaluatingJurorsInSession;
    this.jurorGroups = jurorGroups;
  }

  static from = (jurorGroupEvaluationsOverview) => {
    return new JurorGroupEvaluationsOverview(
      jurorGroupEvaluationsOverview.maxPointsPerCriterion,
      jurorGroupEvaluationsOverview.numCriteria,
      jurorGroupEvaluationsOverview.numJurorsInSession,
      jurorGroupEvaluationsOverview.numEvaluatingJurorsInSession,
      JurorGroupEvaluations.of(jurorGroupEvaluationsOverview.jurorGroups)
    );
  };

  static fetchForSession = async (sessionId) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/jurorGroupsOverview`)
      .then((response) => response.json())
      .then((jurorGroupEvaluations) =>
        JurorGroupEvaluationsOverview.from(jurorGroupEvaluations)
      );
  };
}

export default JurorGroupEvaluationsOverview;
