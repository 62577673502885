import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import {
  faEmptySet,
  faHand,
  faTriangleExclamation,
  faMedal,
  faUser,
  faTrophy,
} from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';
import ProgressCircle from '../../components/progressCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { combine } from '../../helpers/styles';
import {
  getAverageScore,
  getProgressPrecentage,
} from '../../helpers/statistic';
import { SESSION_TYPES } from '../../models/session';
import { isAwardType } from '../../helpers/awardTypes';
import { AWARD_TYPES } from '../../models/award';
import Loading from '../../components/loading';

const isPreselection = (session) =>
  session.sessionType === SESSION_TYPES.PRESELECTION;

const AwardStatistic = ({
  groupEvaluations,
  session,
  isLoading,
  qualificationThreshold,
}) => {
  const { t } = useTranslation();

  const mappedStatistics = useMemo(() => {
    if (
      !groupEvaluations ||
      (!qualificationThreshold && isAwardType(AWARD_TYPES.IF_DA, session)) ||
      !session
    )
      return [];

    const awardStats = groupEvaluations.jurorGroups.reduce(
      (stats, group) => ({
        totalEvaluationCount:
          stats.totalEvaluationCount +
          group.numEvaluatingJurorsInGroup * group.numSubmissionsInGroup,
        decidedEvaluationCount:
          stats.decidedEvaluationCount + group.numDecidedEvaluations,
        totalPoints:
          stats.totalPoints + group.totalPointsFromAllDecidedEvaluations,
        totalNominations:
          stats.totalNominations +
          (isPreselection(session) || isAwardType(AWARD_TYPES.IF_DTA, session)
            ? group.numSubmissionsWithSuggestedForNomination
            : group.numNominationsInGroup),
        decidedByChairperson:
          stats.decidedByChairperson +
          group.numEvaluationsWithChairPersonMustDecide,
        scoreAlerts: stats.scoreAlerts + group.numScoreAlerts,
      }),
      {
        totalEvaluationCount: 0,
        decidedEvaluationCount: 0,
        totalPoints: 0,
        totalNominations: 0,
        decidedByChairperson: 0,
        scoreAlerts: 0,
      }
    );

    const stats = [
      {
        type: 'progressPercentage',
        value: getProgressPrecentage(
          awardStats.decidedEvaluationCount,
          awardStats.totalEvaluationCount
        ),
      },
      {
        icon: faEmptySet,
        label: t('sessions.evaluation.statistics.averageScore'),
        type: 'averageScore',
        value: getAverageScore(
          awardStats.decidedEvaluationCount,
          awardStats.totalPoints
        ),
      },
      {
        icon: faMedal,
        label: t(
          `sessions.evaluation.statistics.nomination.${session?.sessionType}`
        ),
        type: 'awarded',
        value: awardStats.totalNominations,
      },
      {
        icon: faHand,
        label: t('sessions.evaluation.statistics.entriesChairperson'),
        type: 'toBeDecidedByChairperson',
        value: awardStats.decidedByChairperson,
      },
      {
        icon: faTriangleExclamation,
        label: t('sessions.evaluation.statistics.scoreAlerts'),
        type: 'scoreAlerts',
        value: awardStats.scoreAlerts,
      },
      {
        icon: faUser,
        label: t('sessions.evaluation.statistics.jurors'),
        type: 'jurors',
        value: groupEvaluations.numEvaluatingJurorsInSession,
      },
    ];

    if (isAwardType(AWARD_TYPES.IF_DA, session)) {
      stats.push({
        icon: faTrophy,
        label: t('sessions.evaluation.statistics.awardedThreshold.label'),
        type: 'awardedThreshold',
        value: qualificationThreshold,
        subValue: t(
          `sessions.evaluation.statistics.awardedThreshold.subValue.${session?.sessionType}`
        ),
      });
    }

    return stats;
  }, [groupEvaluations, session, qualificationThreshold]);

  return (
    <div className={styles.awardStatistic}>
      <Container className={styles.container}>
        <h2 className={styles.title}>
          <Trans
            i18nKey="sessions.evaluation.statistics.title"
            components={{ bold: <b /> }}
          />
        </h2>
        {isLoading && (
          <div className={styles.loadingSpinner}>
            <Loading />
          </div>
        )}
        <div
          className={combine(
            styles.statisticItemsContainer,
            isAwardType(AWARD_TYPES.IF_DA, session) && styles.finalJury
          )}
        >
          {mappedStatistics?.map((stat) => (
            <div key={stat.type} className={styles.column}>
              {stat.type === 'progressPercentage' ? (
                <div className={styles.valueContainer}>
                  <ProgressCircle
                    progress={stat.value}
                    subtitle={t('sessions.evaluation.statistics.done')}
                  />
                </div>
              ) : (
                <div className={styles.valueContainer}>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={stat.icon}
                    size="2x"
                  />
                  <span className={styles.value}>{stat.value}</span>
                  <span className={styles.label}>{stat.label}</span>
                </div>
              )}
              {stat.subValue && (
                <span className={styles.subValue}>{stat.subValue}</span>
              )}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

AwardStatistic.propTypes = {
  groupEvaluations: PropTypes.object,
  isLoading: PropTypes.bool,
};

AwardStatistic.defaultProps = {
  groupEvaluations: null,
  isLoading: false,
};

export default AwardStatistic;
