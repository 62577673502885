import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/loading';
import { combine } from '../../../../helpers/styles';
import { useJuror } from '../../../../hooks/useAuth';
import Submission from '../../../../models/submission';
import useQrCodeScanner, {
  SCAN_STATES,
} from '../../../../hooks/useQrCodeScanner';
import styles from './styles.module.scss';

const VIDEO_STREAM_HTML_ELEMENT_ID = 'camera-video-stream';

const QRScanner = () => {
  const { entryId } = useParams();
  const { juror } = useJuror();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { scanState, data } = useQrCodeScanner(VIDEO_STREAM_HTML_ELEMENT_ID);

  useEffect(() => {
    if (data || entryId) {
      const submissionId = entryId || data.split('/').pop();
      Submission.getEvaluations(submissionId).then((evaluations) => {
        const matchingJurorGroup = juror.jurorGroups.find(
          (jg) => jg.jurorGroupId === evaluations[0]?.jurorGroupId
        );

        if (matchingJurorGroup) {
          return navigate(`/juror/entry/${submissionId}`, {
            state: {
              jurorGroupId: matchingJurorGroup.jurorGroupId,
            },
          });
        }

        return navigate(
          `/juror/entry/${submissionId}/${evaluations[0]?.jurorId}`,
          {
            state: {
              jurorGroupId: evaluations[0]?.jurorGroupId,
            },
          }
        );
      });
    }
  }, [data, entryId]);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{t('submissionScan.title')}</h2>
        <p className={styles.instructions}>
          {t('submissionScan.instructions')}
        </p>
      </div>
      <div className={styles.scannerContainer}>
        {scanState === SCAN_STATES.NOT_SCANNING && !data && (
          <span className={styles.accessText}>
            {t('submissionScan.cameraAccess')}
          </span>
        )}
        {!data && (
          <video
            className={combine(styles.scanner, styles.active)}
            id={VIDEO_STREAM_HTML_ELEMENT_ID}
            width="500"
            height="500"
          ></video>
        )}
        {scanState !== SCAN_STATES.SCANNING && data && (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default QRScanner;
