import {
  faBell,
  faBookmark as faBookmarkSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBookmark,
  faCameraSlash,
  faExpand,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Carousel,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { SIZING_MATRIX_ICONS } from '../../../helpers/fontawesome';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import Submission from '../../../models/submission';
import Button, { ButtonSizes, ButtonTypes } from '../../button';
import FullScreenImage from '../entryFullScreenImage';
import styles from './styles.module.scss';
import { isAwardType } from '../../../helpers/awardTypes';
import { AWARD_TYPES } from '../../../models/award';
import { SESSION_TYPES } from '../../../models/session';

const UpdatedContentOverlayWrapper = ({ children }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className={styles.tooltip} {...props}>
      <span>{t('evaluation.buttons.updatedContentMessage')}</span>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 600 }}
      overlay={renderTooltip}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
        ],
      }}
    >
      {children}
    </OverlayTrigger>
  );
};

const EntryDetailHeader = ({ submission, handleSubmissionUpdate }) => {
  const [bookmarkHover, setBookmarkHover] = useState(false);
  const { jurorGroup, session, isPreview, juror } = useJuror();
  const [fullScreen, setFullscreen] = useState(false);
  const [images, setImages] = useState(submission?.fields?.images || []);
  const { state } = useLocation();

  const isFieldUpdated = (field) => {
    // Show update notification only for IF_DA && FINAL_JURY
    const showUpdateNotification =
      isAwardType(AWARD_TYPES.IF_DA, session) &&
      session?.sessionType === SESSION_TYPES.FINAL_JURY;

    if (!showUpdateNotification || !juror?.predecessorSessionEndDate) {
      return false;
    }

    const threshold = moment(juror.predecessorSessionEndDate);

    return moment(field.updated).isAfter(threshold);
  };

  const handleBookmark = () => {
    return Submission.setBookmark(
      !submission.bookmarked,
      state?.jurorGroupId || jurorGroup.jurorGroupId,
      submission.submissionId
    ).then((isOk) => {
      if (isOk) {
        handleSubmissionUpdate({
          ...submission,
          bookmarked: !submission.bookmarked,
        });
      }
    });
  };

  const closeFullScreen = () => {
    setFullscreen(false);
  };

  useEffect(() => {
    if (!submission) return;

    setImages([
      ...(submission?.fields?.images ?? []),
      ...(submission?.fields?.contextDimensions ?? []),
    ]);
  }, [submission]);

  return (
    <>
      {fullScreen && (
        <FullScreenImage
          image={fullScreen}
          onClose={closeFullScreen}
          carousel={images}
        />
      )}
      <Container fluid>
        <Container className={styles.entryContainer}>
          <div className={styles.entryInformation}>
            <div className={styles.entryInformationLeft}>
              <span className={styles.entryId}>
                {t('evaluation.EntryID')}{' '}
                <span className={styles.bold}>{submission?.submissionId}</span>
              </span>
              <h1 className={styles.title}>{submission?.submissionName}</h1>
              <h2 className={styles.subtitle}>
                {submission?.fields.entryType}
              </h2>
            </div>
            <div className={styles.entryInformationRight}>
              <span className={styles.entryId}>
                {t(`evaluation.Section.${session?.awardType}`)}{' '}
                <span className={styles.bold}>{submission?.categoryName}</span>
              </span>
              {!isPreview && (
                <FontAwesomeIcon
                  onMouseEnter={() => setBookmarkHover(true)}
                  onMouseLeave={() => setBookmarkHover(false)}
                  onClick={handleBookmark}
                  role="button"
                  color="#ed1d26"
                  size="2x"
                  className={combine(styles.bookmark)}
                  icon={
                    submission?.bookmarked || bookmarkHover
                      ? faBookmarkSolid
                      : faBookmark
                  }
                />
              )}
            </div>
          </div>
        </Container>
        <Container className={styles.entryMain}>
          <Row>
            <Col sm={12} lg={8}>
              <Carousel
                touch
                indicators={!!submission?.submissionPrimaryMediaUrl}
                controls={!!submission?.submissionPrimaryMediaUrl}
                className={styles.entryCarousel}
                interval={null}
              >
                {!submission?.submissionPrimaryMediaUrl ? (
                  <Carousel.Item
                    className={combine(styles.carouselSlide, styles.empty)}
                  >
                    <FontAwesomeIcon icon={faCameraSlash} size={'5x'} />
                  </Carousel.Item>
                ) : (
                  images?.map((media, index) => (
                    <Carousel.Item
                      key={index}
                      className={styles.carouselSlide}
                      style={{
                        backgroundImage: `url(${media.url})`,
                      }}
                    >
                      <div className={styles.fullScreenBtn}>
                        <FontAwesomeIcon
                          onClick={() => setFullscreen(media.url)}
                          icon={faExpand}
                          className={styles.iconFullScreen}
                        />
                      </div>
                    </Carousel.Item>
                  ))
                )}
              </Carousel>
            </Col>
            <Col sm={12} lg={4} className={styles.entryDescription}>
              <p className={styles.entryDescriptionInner}>
                {submission?.fields?.designStatement ||
                  submission?.fields?.statement}
              </p>
              <Row>
                <Col>
                  {(submission?.fields?.dimensionsWidth ||
                    submission?.fields?.dimensionsHeight ||
                    submission?.fields?.dimensionsDepth) && (
                    <span className={styles.detailValue}>
                      Size{' '}
                      <span className={styles.bold}>
                        {submission?.fields?.dimensionsWidth} x{' '}
                        {submission?.fields?.dimensionsHeight} x{' '}
                        {submission?.fields?.dimensionsDepth}
                      </span>
                    </span>
                  )}
                  {submission?.fields?.dimensionsWeight && (
                    <span className={styles.detailValue}>
                      Weight{' '}
                      <span className={styles.bold}>
                        {submission?.fields?.dimensionsWeight}
                      </span>
                    </span>
                  )}
                  <span className={styles.detailCompany}>
                    {submission.fields?.client_manufacturer
                      ?.map((item) => item.companyName)
                      .join(',') ||
                      submission.fields?.submittingOrganization
                        ?.map((item) => item.companyName)
                        .join(',')}
                  </span>
                </Col>
                {submission?.fields?.sizingMatrix && (
                  <Col className={styles.sizingIconWrapper}>
                    <FontAwesomeIcon
                      icon={SIZING_MATRIX_ICONS[submission.fields.sizingMatrix]}
                      size={'2x'}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row className={styles.informationRow}>
            {submission?.fields?.projectPDF?.value?.map((pdf) => (
              <Col xs={4} sm={2}>
                <a href={pdf.url} target={'_blank'} rel={'noopener noreferrer'}>
                  <Button
                    text={t(`evaluation.buttons.file.${session?.awardType}`)}
                    size={ButtonSizes.LARGE}
                    type={ButtonTypes.SECONDARY}
                    className={styles.informationButtons}
                    notificationIcon={
                      isFieldUpdated(submission.fields.projectPDF) && faBell
                    }
                    wrapperComponent={
                      isFieldUpdated(submission.fields.projectPDF) &&
                      UpdatedContentOverlayWrapper
                    }
                  />
                </a>
              </Col>
            ))}
            {submission?.fields?.url && (
              <Col xs={4} sm={2}>
                <a
                  href={submission?.fields?.url.value.url}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <Button
                    text={t(`evaluation.buttons.link.${session?.awardType}`)}
                    size={ButtonSizes.LARGE}
                    type={ButtonTypes.SECONDARY}
                    className={styles.informationButtons}
                    notificationIcon={
                      isFieldUpdated(submission.fields.url) && faBell
                    }
                    wrapperComponent={
                      isFieldUpdated(submission.fields.url) &&
                      UpdatedContentOverlayWrapper
                    }
                  />
                </a>
              </Col>
            )}
            {(submission?.fields?.url?.value?.username ||
              submission?.fields?.url?.value?.password) && (
              <Col xs={4} sm={2} className={styles.protectedUrl}>
                {submission.fields.url.value?.username && (
                  <div className={styles.protectedLabel}>
                    <span className={styles.label}>
                      {t('evaluation.file.username')}
                    </span>
                    <span className={styles.value}>
                      {submission.fields.url.value.username}
                    </span>
                  </div>
                )}
                {submission.fields.url.value?.password && (
                  <div className={styles.protectedLabel}>
                    <span className={styles.label}>
                      {t('evaluation.file.password')}
                    </span>
                    <span className={styles.value}>
                      {submission.fields.url.value.password}
                    </span>
                  </div>
                )}
              </Col>
            )}
            {submission?.fields?.video && (
              <Col xs={4} sm={2}>
                <a
                  href={submission.fields.video.value?.url}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <Button
                    text={t('evaluation.buttons.video')}
                    size={ButtonSizes.LARGE}
                    type={ButtonTypes.SECONDARY}
                    className={styles.informationButtons}
                    notificationIcon={
                      isFieldUpdated(submission.fields.video) && faBell
                    }
                    wrapperComponent={
                      isFieldUpdated(submission.fields.video) &&
                      UpdatedContentOverlayWrapper
                    }
                  />
                </a>
              </Col>
            )}
            <Col xs={4} sm={2} className={styles.protectedUrl}>
              {submission?.fields?.video?.value?.username && (
                <div className={styles.protectedLabel}>
                  <span className={styles.label}>
                    {t('evaluation.file.username')}
                  </span>
                  <span className={styles.value}>
                    {submission.fields.video.value.username}
                  </span>
                </div>
              )}
              {submission?.fields?.video?.value?.password && (
                <div className={styles.protectedLabel}>
                  <span className={styles.label}>
                    {t('evaluation.file.password')}
                  </span>
                  <span className={styles.value}>
                    {submission.fields.video.value.password}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

EntryDetailHeader.propTypes = {
  submission: PropTypes.object,
  handleSubmissionUpdate: PropTypes.func,
};

EntryDetailHeader.defaultProps = {
  submission: {},
  handleSubmissionUpdate: () => {},
};

export default EntryDetailHeader;
