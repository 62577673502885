import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import * as React from 'react';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

export const ButtonTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const ButtonSizes = {
  SMALL: 'small',
  LARGE: 'large',
};

const Wrapper = ({ children, wrapperComponent }) => {
  if (!wrapperComponent) {
    return <>{children}</>;
  }

  return React.createElement(wrapperComponent, {}, children);
};

const Button = ({
  text,
  className,
  disabled,
  onClick,
  size,
  type,
  icon,
  iconBold,
  htmlType,
  notificationIcon,
  wrapperComponent,
}) => {
  return (
    <Wrapper wrapperComponent={wrapperComponent}>
      <button
        data-label={text}
        type={htmlType}
        className={combine(
          styles.button,
          styles[type],
          styles[size],
          className,
          icon && styles.withIcon
        )}
        disabled={disabled}
        onClick={onClick}
      >
        <span className={styles.label}>{text}</span>
        {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
        {iconBold && (
          <FontAwesomeIcon
            className={combine(styles.icon, styles.iconBold)}
            icon={iconBold}
          />
        )}
        {notificationIcon && (
          <div className={styles.notificationIconContainer}>
            <FontAwesomeIcon
              icon={notificationIcon}
              className={styles.notificationIcon}
            />
          </div>
        )}
      </button>
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['normal', 'small', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconBold: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  htmlType: PropTypes.string,
  notificationIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperComponent: PropTypes.element,
};

Button.defaultProps = {
  text: 'Button',
  className: '',
  disabled: false,
  onClick: () => {},
  size: 'normal',
  type: 'primary',
  icon: null,
  iconBold: null,
  htmlType: 'submit',
  notificationIcon: null,
  wrapperComponent: null,
};

export default Button;
